import * as React from 'react';
import {useLocation} from "react-router-dom";
// import '../../assets/css/error.scss';
import {useGetPageByUrlQuery} from "../../services/flatepage";
import {toggleDialog, togglePriceTableDialog, toggleTimeTableDialog} from "../dialog/dialogSlice";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {Meta} from "../base/Meta";
import {SliderSimplePage} from "./SliderSimplePage";
import {BreadCrumbsBlock} from "./BreadCrumbsBlock";

interface SimpleBlockProp {
    title: string,
    children?: JSX.Element;
    children_bread?: JSX.Element,
    bread_name?:string
}

export const SimpleBlock = (props: SimpleBlockProp): JSX.Element => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const {pathname} = location
    const {data: simplePageObject, isLoading, isSuccess, isFetching, isError} = useGetPageByUrlQuery({
        url: pathname,
        omit: 'slider'
    });
    const clickHandler = (e: React.MouseEvent<HTMLElement>) => {
        let el = e.target;
        const buttonMode = (e.target as HTMLButtonElement).getAttribute('data-type');
        if (buttonMode) {
            if (buttonMode == 'price') {
                dispatch(togglePriceTableDialog({}));
            }
            if (buttonMode == 'timetable') {
                dispatch(toggleTimeTableDialog({}))
            }
            if (buttonMode == 'lesson') {
                dispatch(toggleDialog({
                    component: 'LessonDialog',
                    title: 'Записаться на занятие',
                    id: 'modal-lessons'
                }));
            }
        }
        // @ts-ignore
        if (el.className == 'headanswer-head') {
            // @ts-ignore
            console.log('==============', el.nextElementSibling);
        }
    }

    const {name, content, content2, metatags, slider} = {...simplePageObject}
    return (
        <React.Fragment>
            {
                (metatags) ? <Meta {...metatags} /> :
                    <Meta title={props.title}/>
            }

            {
                (props.children_bread) ?
                    <BreadCrumbsBlock children={props.children_bread} items={[{name: props.bread_name || name, link: pathname}]}/> : null
            }
            {
                (isSuccess) ?
                    <>
                        <h1>{(name) ? name : props.title}</h1>
                        {
                            (slider && slider.length > 1) ? <SliderSimplePage slider={slider}/> : null
                        }
                        {
                            (content) ? <div onClick={clickHandler} dangerouslySetInnerHTML={{__html: content}}/> : null
                        }
                        {
                            (props.children) ? <>{props.children}</> : null
                        }
                        {
                            (content2) ?
                                <div onClick={clickHandler} dangerouslySetInnerHTML={{__html: content2}}/> : null
                        }
                    </> : <h1></h1>
            }


        </React.Fragment>
    );


};
