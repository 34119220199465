import * as React from 'react';
import App from './App';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import {store} from './config/store';
import { YMInitializer } from 'react-yandex-metrika';
import './assets/css/index.scss';

const root = createRoot(document.getElementById('root'));


root.render(
    <>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
        <YMInitializer accounts={[91843009]} options={{webvisor: true}} version="2"/>
    </>
);
