import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../../config/store';
import {InitialBranchCity} from "../../../services/cities";

interface InitialState {
    select_city: {
        id: number;
        name: string,
        c_branch?: number
    },
    select_branch: BranchCity,
    lat: number,
    lon: number
}

export function getStorageItem(key: string) {
    try {
        return Number(localStorage.getItem(key));
    } catch (e) {
        return 0;
    }
}

const selectCityLocalStorage = getStorageItem('cityId')
const selectBranchLocalStorage = getStorageItem('branchId')

const initialState: InitialState = {
    select_city: {
        id: selectCityLocalStorage,
        name: '',
        c_branch: 0
    },
    select_branch: {
        ...InitialBranchCity,
        id: selectBranchLocalStorage,
    },
    lat:0,
    lon:0
};


export const citySlice = createSlice({
    name: 'city',
    initialState,
    reducers: {
        setCity: (state, action) => {
            if (action.payload) {
                state.select_city = action.payload
                localStorage.setItem('cityId', JSON.stringify(action.payload.id))
            }
        },
        setBranch: (state, action) => {
            if (action.payload) {
                state.select_branch = action.payload
                localStorage.setItem('branchId', JSON.stringify(action.payload.id))
            }
        },
        setGeoPosition: (state, action) => {
            if (action.payload) {
                state.lat = action.payload.lat;
                state.lon = action.payload.long
                localStorage.setItem('position_lat', JSON.stringify(action.payload.lat))
                localStorage.setItem('position_long', JSON.stringify(action.payload.long))
            }
        },
    },
});

export const {setCity, setBranch, setGeoPosition} = citySlice.actions;

export const mainCity = (state: RootState) => state.city.select_city;
export const mainBranch = (state: RootState) => state.city.select_branch;
export const PositionLat = (state: RootState) => state.city.lat;
export const PositionLon = (state: RootState) => state.city.lon;


export default citySlice.reducer;
