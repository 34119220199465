import * as React from 'react';
import {useEffect, useState} from 'react';
import {SelectCityButton} from "./SelectCityButton";
import {useGetMenuByAliasQuery} from "../../../services/menus";
import clsx from "clsx";
import {LinkOrUrl} from "../../base/LinkOrURL";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {mainBranch} from "../../dialog/components/cityReducer";
import {toggleConsDialog} from "../../dialog/dialogSlice";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {SocialBlock} from "../../base/SocialBlock";
import { paramsMenu, toggleLetSee } from './headerSlice';
import { SettingsModal } from '@n3/react-vision-panel';
import {
    defaultGroups,
    defaultSetBodyClass,
    openSettingsModal,
} from '@n3/react-vision-panel';

interface MainMenuProp {
    isMenuOpen: boolean,
    toggleMenu: React.MouseEventHandler,
    menuRef: any,
    alias: string,
    alias_burger: string
}

export const MainMenu = (props: MainMenuProp): JSX.Element => {
    const dispatch = useAppDispatch();
    const clickRef = React.useRef();
    const {isLetSee} = useAppSelector(paramsMenu);
    const {isMenuOpen, toggleMenu, alias, alias_burger, menuRef} = props;
    const [menuList, setMenuList] = useState([]);
    const {data: menus, isSuccess} = useGetMenuByAliasQuery({
        alias: alias
    });

    useEffect(() => {
        if (isSuccess) {
            setMenuList([...menus.children]);
        }
    }, [menus]);

    const {data: menus2, isSuccess: isSuccess2} = useGetMenuByAliasQuery({
        alias: alias_burger
    });

    const [menu2List, setMenu2List] = useState([]);
    useEffect(() => {
        if (isSuccess2) {
            setMenu2List([...menus2.children]);
        }
    }, [menus2]);

    const branch = useAppSelector(mainBranch);

    const handleCons = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(toggleConsDialog());
    };

    const handleLetSeeClick = () => {
        dispatch(toggleLetSee())
    };
    console.log('isLetSee===', isLetSee)

    return (
        <nav className={clsx("nav", isMenuOpen && "active")} ref={menuRef}>
            <div className="wrapper">
                <div className="close" onClick={toggleMenu}></div>
                <SelectCityButton/>
                <ul>
                    {menuList.map((item: ItemMenu, index) => {
                        let isChildren = item.children.length;
                        return (
                            <li key={item.id}>
                                <LinkOrUrl class={clsx("", isChildren && 'sub')} url={item.url} title={item.title}/>

                                {
                                    (isChildren) ?
                                        <ul className={`navigation-links navigation-links--${index+1}`}>
                                            {item.children.map((subItem: ItemMenu) => {
                                                return (
                                                    <li key={subItem.id}>
                                                        <LinkOrUrl url={subItem.url} title={subItem.title}/>
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul> : null
                                }
                            </li>
                        )
                    })
                    }
                    {menu2List.map((item: ItemMenu, i, arr) => {
                            let isChildren = item.children.length;

                            return (
                                <li className={"mob-show"} key={item.id}>
                                    <LinkOrUrl class={"sub"} url={item.url} title={item.title}/>
                                    {
                                        (isChildren) ?
                                            <ul className={'navigation-links'}>
                                                {item.children.map((subItem: ItemMenu) => {
                                                    return (
                                                        <li key={subItem.id}>
                                                            <LinkOrUrl url={subItem.url} title={subItem.title}/>
                                                        </li>
                                                    )
                                                })
                                                }
                                            </ul> : null
                                    }
                                </li>
                            )
                        })
                        }
                </ul>
                <div className="header__icons">
                    <div className="vision-panel" onClick={(e) => handleLetSeeClick()}><i className="icon-eye"></i></div>
                    <SettingsModal
                        show={isLetSee}
                        onHide={handleLetSeeClick}
                        restoreButtonText='Обычная версия'
                        closeButtonText='Закрыть'
                    />
                    <div className="header__burger" onClick={toggleMenu}>
                        <i/><i/><i/>
                    </div>
                </div>
            </div>
            <div className="bottom-menu header__bottom-menu">
                <div className="wrapper">
                    <div className="bottom-menu__block bottom-menu__block--top">
                        {menu2List.map((item: ItemMenu, i, arr) => {
                            let isChildren = item.children.length;

                            return (
                                <div className={"bottom-menu__col"} key={item.id}>
                                    <LinkOrUrl class={"bottom-menu__title"} url={item.url} title={item.title}/>
                                    {
                                        (isChildren) ?
                                            <ul className="bottom-menu__links">
                                                {item.children.map((subItem: ItemMenu) => {
                                                    return (
                                                        <li className="bottom-menu__link" key={subItem.id}>
                                                            <LinkOrUrl url={subItem.url} title={subItem.title}/>
                                                        </li>
                                                    )
                                                })
                                                }
                                            </ul> : null
                                    }
                                    {
                                        (i==2) ? 
                                        <div className="bottom-menu__col margin-t">
                                            <a href="/social">
                                                <span className="bottom-menu__title">Социальное предпринимательство</span><br/>
                                                <span className="bottom-menu__icon-wrapper">
                                                    <img src="/assets/images/beating-heart.svg" alt=""/>
                                                </span>
                                            </a>
                                        </div>: null
                                    }
                                </div>
                            )
                        })
                        }
                    </div>
                    <div className="bottom-menu__col margin-t visible-xs">
                        <a href="/social">
                            <span className="bottom-menu__title">Социальное предпринимательство</span><br/>
                            <span className="bottom-menu__icon-wrapper">
                                <img src="/assets/images/beating-heart.svg" alt=""/>
                            </span>
                        </a>
                    </div>
                    <div className="bottom-menu__block bottom-menu__block--bottom">
                        {
                            (Object.keys(branch.social || {}).length) ?
                                <div className="bottom-menu__col">
                                    <p className="bottom-menu__title">Мы в соцсетях</p>
                                    <SocialBlock branch={branch}/>
                                </div> : null
                        }
                        <div className="bottom-menu__col">
                            <a className="btn btn-yellow" href="" onClick={handleCons}>Обратная связь</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>

    );
};

