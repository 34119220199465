import * as React from 'react';
import {useEffect, useState} from 'react';
import {useGetCityQuery} from '../../../services/cities';
import {useAppSelector} from "../../../hooks/useAppSelector";
import {mainCity, setBranch, setCity} from "./cityReducer";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import clsx from "clsx";
import {CountBranch} from "../../base/CountBranch";
import {toggleDialog} from "../dialogSlice";

export interface OptionCity {
    id: any;
    name: string;
    main?: boolean;
    branch?: {
        id: any;
        name: string;
        phone?: string;
        social: object
    };
    c_branch?: number
}

export const CityDialog = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const [objectsList, setList] = useState([]);
    const {data: objects} = useGetCityQuery();
    const city = useAppSelector(mainCity);

    const [city_, setCity_] = useState(city);
    useEffect(() => {
        if (objects) {
            setList([...objectsList, ...objects]);
        }
    }, [objects]);

    function handlerSelectCity(selectedOption: OptionCity | null) {
        setCity_(selectedOption)
    }

    function handlerSaveCity(selectedOption: OptionCity | null) {
        dispatch(setCity(selectedOption))
        dispatch(setBranch(selectedOption.branch))
        dispatch(toggleDialog())
    }
    const countBranch = city_.c_branch || 0;
    return (
        <div>
            <ul className="city-modal__cities">
                {objectsList.map((item: OptionCity, i) => {
                    return (
                        <li className={clsx("city-modal__city", city_.id == item.id && 'current')} key={item.id}>
                            <button disabled={!item.c_branch} className="btn--reset"
                                    onClick={() => handlerSelectCity(item)}>
                                {(city.id == item.id) ? <React.Fragment>
                                    {
                                        (city_.id == item.id) ?
                                            <i className={"icon-map active"}/>:
                                            <i className={"icon-map"}/>
                                    }
                                </React.Fragment> : null}
                                <span>{item.name}</span>
                            </button>
                        </li>
                    )
                })
                }
            </ul>
            <button className="headlist-btn city-modal__btn" onClick={() => handlerSaveCity(city_)}>
                <span className={'icon-map'}></span>
                Сохранить
            </button>
            <div className="header__city city-modal__chose">
                <div className="header__city-name">
                    {city_.name},
                    <span>&nbsp;<CountBranch countBranch={countBranch}/>
                    </span>
                </div>
            </div>
        </div>
    );
};
CityDialog.defaultProps = {
    city: 1,
}

